<template>
  <div :class="{ '-mixing': isMixing }" class="ingredients">
    <Categories />
    <Ingredients file-type="png" />
    <Mixer :start-mixing="startMixing" />
    <GuideText :show="showGuideText" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Categories, Ingredients, Mixer, GuideText } from '@/components'

export default {
  components: {
    Categories,
    Ingredients,
    Mixer,
    GuideText,
  },
  computed: {
    ...mapGetters({
      ingredients: 'recipe/ingredients',
    }),
  },
  data: () => ({
    showGuideText: false,
    isMixing: false,
  }),
  methods: {
    startMixing() {
      this.isMixing = true
    }
  },
  mounted() {
    const { name } = this.$route
    if (window.localStorage.getItem('pauluns_instructions_displayed') && name === 'ingredients') {
      this.showGuideText = true
    }
  },
  watch: {
    $route (to, from){
      if (from.name === 'info' && to.name === 'ingredients' && this.ingredients.length === 0) {
        this.showGuideText = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ingredients {
  display: flex;
  flex-direction: column;
  
  /deep/ .info,
  /deep/ .custom-ingredients  {
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
  }

  &.-mixing {
    > .categories,
    > .ingredients,
    /deep/ .badge,
    /deep/ .add-ingredient,
    /deep/ .my-ingredients,
    /deep/ .continue {
      opacity: 0;
    }

    /deep/ .blender {
      transform: translateY(-50px);
    }
  }

  @media #{$mobile-xs} {
    display: block;

    /deep/ .mixer {
      @include rem(margin-bottom, $gutter);
      margin-top: -30px;
      position: relative;
      bottom: 0;
      flex-direction: row;
      overflow: auto;
      left: 0;
      transform: none;
    }
  }

  @media #{$desktop-sm} {
    /deep/ .badge {
      @include rem(font-size, 20px);
      @include rem(line-height, 28px);
      @include rem(width, 32px);
      @include rem(height, 32px);
    }
  }

  @media #{$wide} {
    @include rem(padding-bottom, $gutter * 3);

    .arrow {
      margin-top: 0;
    }
  }
}
</style>
